.Logo {
  max-width: 227px;
  width: 100%;
  height: auto;
  justify-content: flex-start;
}
.Logo a {
  display: block;
}
@media screen and (max-width: 1024px) and (min-width: 768px){
  .Logo {
    max-width: 190px;
  }
}
@media screen and (max-width: 767px) {
  .Logo {
    max-width: 160px;
  }
}
