.testimonial-img {
  margin-bottom: 15px;
}
.testimonial-img:last-child {
  margin-bottom: 0;
}
.testomonial--sec .title {
  margin-bottom: 42px;
}
@media screen and (max-width: 767px) {
  .testomonial--sec {padding: 0 8px;}
  .testomonial--sec .title {
    margin-bottom: 25px;
  }
  .testimonial-img {
    margin-bottom: 0;
  }
}