.post--your--job,
.post--your--job .btn-container {
  float: left;
  width: 100%;
  border-top: 1px solid #d9d9d9;
  margin-top: 30px;
  padding-top: 80px;
}
.post--your--job .btn-container {
  border: none;
  padding-top: 0;
}
.post--your--job .head-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  padding: 0;
  margin: 0;
}
.post--your--job .head-sec h1.title {
  padding-bottom: 0;
}
.post--your--job .thumb {
  width: 90px;
  height: 90px;
}
.post--your--job .btn {
  background-color: #333;
  color: #bdbdbd;
  border: none;
  position: relative;
}
.btn.mentorship--applying {
  padding-left: 45px;
  margin-right: 10px;
}
.btn.job-applying {
  padding-left: 45px;
}
.btn.mentorship--applying:before {
  background: url(../../assets/Images/mentorship--icon.png);
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 30.43px;
  height: 29.17px;
  content: "";
  margin-top: -14.585px;
}
.btn.job-applying:before {
  background: url(../../assets/Images/post-job-icon.png);
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 10px;
  width: 21.6px;
  height: 18px;
  content: "";
  margin-top: -9px;
}

@media screen and (max-width:1024px) and (min-width:768px) {
  .post--your--job, .post--your--job .btn-container {
    padding-top: 50px;
  }
  .post--your--job .btn-container {margin: 0;}
}
@media screen and (max-width: 767px) {
  .post--your--job, 
  .post--your--job .btn-container {
    padding-top:30px;
  }
  .post--your--job .head-sec  {
    flex-direction: column-reverse;
    text-align: center;
  }
  .post--your--job {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .post--your--job p {margin-top: 10px;}
  h1.title {
    font-size: 18px;
    line-height: 28px;
    padding: 20px 0;
  }
  .post--your--job .btn-container {
    margin-top: 0;
  }
}