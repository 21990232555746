.responsive-menu {
  display: none;
  width: 50px;
}
.responsive--menu {
  color: #fff;
  right: 20px;
  top: 5px;
  position: absolute;

}
.closeIcon {
  position: absolute;
  right: 22px;
  color: #fff;
  font-size: 1.5rem;
  top: 10px;
}
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #000;
  transition: all 0.5s ease-in-out;
  z-index: 99;
  transform: translateX(100%);
}
.menu-btn {
  position: absolute;
  z-index: 999;
  right: 0px;
  /* left: 20px; */
  top: 3px;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  display: none;
  /*background: linear-gradient(-135deg, #c850c0, #4158d0);*/
  /* background: linear-gradient(375deg, #1cc7d0, #2ede98); */
  /* background: linear-gradient(-45deg, #e3eefe 0%, #efddfb 100%); */
  transition: all 0.3s ease-in-out;
}
#active:checked ~ .menu-btn i:before {
  background-image: url('../../../assets/Images/close-menu-icon.jpeg');
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
}
.wrapper ul {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  list-style: none;
  text-align: center;
  width: 100%;
  padding-left: 0;
}
.wrapper ul li {
  margin: 15px 0;
}
.wrapper ul li a {
  color: none;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 5px 30px;
  color: #fff;
  border-radius: 50px;
  background: #000;
  position: relative;
  line-height: 28px;
  transition: all 0.3s ease;
}
.wrapper ul li a:after {
  position: absolute;
  content: "";
  background: #fff;
  background: linear-gradient(#14ffe9, #ffeb3b, #ff00e0);
  /*background: linear-gradient(375deg, #1cc7d0, #2ede98);*/
  width: 104%;
  height: 110%;
  left: -2%;
  top: -5%; /* if the font is 'Oswald'*/
  border-radius: 50px;
  transform: scaleY(0);
  z-index: -1;
  animation: rotate 1.5s linear infinite;
  transition: transform 0.3s ease;
}
.wrapper ul li a:hover:after {
  transform: scaleY(1);
}
.wrapper ul li a:hover {
  color: #fff;
}
.wrapper.active {
  transform: translateX(0);
}
.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  text-align: center;
  width: 100%;
  color: #202020;
}
.content .title {
  font-size: 40px;
  font-weight: 700;
}
.content p {
  font-size: 35px;
  font-weight: 600;
}

@keyframes rotate {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) { 
  .responsive-menu {
    display: block;
  }
  .menu-btn {
    display: block;
    top: 5px;
    right: 5px;
    font-size: 26px;
  }
}
@media screen and (max-width: 767px) {
  .responsive-menu {
    display: block;
  }
  .menu-btn {
    display: block;
  }
}
