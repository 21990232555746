body.active--modal {
  overflow-y: hidden;
}
.modal--overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 99;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: -100px;
}
.Modal {
  max-width: 358px;
  background-color: black;
  position: relative;
  border: 1px solid #5ffd04;
  box-shadow: 13px 14px 0px -3px rgba(95, 253, 4, 0.41);
  -webkit-box-shadow: 13px 14px 0px -3px rgba(95, 253, 4, 0.41);
  -moz-box-shadow: 13px 14px 0px -3px rgba(95, 253, 4, 0.41);
  text-align: center;
}
.Modal .modal--content {
  padding: 30px;
  font-size: 16px;
  line-height: 24px;
}
.modal--password {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  display: block;
  margin-bottom: 15px;
}
.contact--password,
.get--access input[type="submit"] {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #000;
  display: block;
  padding: 15px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
}
.contact--password:hover,
.get--access input[type="submit"]:hover {
  background-color: transparent;
  color: #fff;
}
.modal--close {
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
.already--pass {
  font-size: 15px;
  line-height: 18px;
}
.Modal input[type="password"],
.get--access input[type="password"] {
  background: transparent;
  border: none;
  border-bottom: 2px solid #bdbdbd;
  padding: 10px;
  text-align: center;
  color: #bdbdbd;
}
.get--access input[type="password"] {
  margin-bottom: 20px;
  width: 100%;
}
.get--access p {
  margin-top: 30px;
  margin-bottom: 0;
}
.get--access button {
  color: #fff;
  font-weight: 700;
  background-color: transparent;
  width: 100%;
  border: 2px solid #fff;
  padding: 15px;
  border-radius: 30px;
  margin-top: 10px;
}
.get--access button:hover {
  background-color: #fff;
  color: #000;
}