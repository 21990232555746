.from--my--experience .head-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.from--my--experience .head-sec .title {
  font-size: 60px;
  line-height: 90px;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 12px;
}
.from--my--experience .btn.subscribe--now {
  margin-right: 10px;
}
.from--my--experience p {
  margin-bottom: 22px;
}
.from--my--experience .slick-list {
  margin: 0 -15px;
}
.from--my--experience .slick-arrow {
  display: none !important;
}

.experience--blog {
  padding: 0 15px;
}
.experience--blog .img-holder {
  max-width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
  overflow: hidden;
}
.experience--blog .text-holder {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  padding-right: 0 !important;
}
.experience--blog .blog--title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .from--my--experience .head-sec {
    padding-bottom: 20px;
  }
  .from--my--experience .head-sec .title {
    font-size: 30px;
    line-height: 50px;
    padding-bottom: 0;
  }
  .from--my--experience p {
    margin-bottom: 0;
  }
  .experience--blog .text-holder p {
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  .from--my--experience .head-sec {
    flex-direction: column;
    align-items: normal;
    margin-bottom: 10px;
  }
  .from--my--experience .head-sec .title {
    font-size: 28px;
    line-height: 50px;
  }
  .from--my--experience p {
    font-size: 18px;
    line-height: 28px;
  }
}
