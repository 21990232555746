.modal--subscribe {
  padding: 30px;
  text-align: left;
  color: #828282;
}
.modal--subscribe .head-area {
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  line-height: 19.7px;
  font-family: "Neue Metana";
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.modal--subscribe .head-area strong {
  font-weight: 700;
  display: block;
}
.modal--subscribe .head-area p {
  margin-bottom: 0;
  padding-top: 41%;
}
.subscribe--desc {
  font-size: 13px;
  line-height: 20px;
}
.subscribe--desc strong {
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
}
.subscribe--desc ul {
  padding: 0;
  margin: 0 0 20px;
}
.subscribe--desc ul li {
  position: relative;
  padding-left: 20px;
  margin: 0 0 8px 10px;
}
.subscribe--desc ul li:after {
  position: absolute;
  left: 0;
  top: 50%;
  background-color: #828282;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  content: "";
  margin-top: -3px;
}
.subscribe--desc input[type="email"] {
  background: transparent;
  border: none;
  border-bottom: 2px solid #bdbdbd;
  padding: 10px;
  text-align: center;
  color: #bdbdbd;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  font-weight: 700;
}
.subscribe--desc input[type="submit"] {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 50px;
  color: #000;
  display: block;
  padding: 15px;
  font-weight: 700;
  margin-bottom: 15px;
  width: 100%;
}
.subscribe--desc input[type="submit"]:hover {
  background-color: transparent;
  color: #fff;
}
