.work--with--brands {
  overflow: hidden;
  font-size: 20px;
  line-height: 40px;
}
.work--with--brands h2.title br {
  display: none;
}
.brands--logo ul {
  padding: 0 0 13px;
}
.brands--logo ul li {
  width: 14.2%;
  display: inline-block;
  vertical-align: middle;
  padding: 35px;
  transition: all 0.5s;
}
.brands--logo ul li:hover {
  transform: scale(1.2);
  opacity: 0.8;
}
.brands--logo ul li:nth-child(1) {
  padding: 30px 0;
}
.brands--logo ul li:nth-child(2) {
  padding: 50px;
}
.brands--logo ul li:nth-child(19) {
  max-width: 100px;
  height: auto;
  padding: 20px;
}
.brands--logo ul li:nth-child(20) {
  max-width: 120px;
  height: auto;
  padding: 25px;
}
/* .work--with--brands p {
  padding-right: 7%;
} */
@media screen and (max-width: 1024px) and (min-width: 768px) { 
  .brands--logo ul li:nth-child(2) {
    padding: 20px;
  }
  .brands--logo ul li {
    padding: 15px;
  }
  .work--with--brands p {
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .work--with--brands .title {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  .brands--logo ul li {
    padding: 10px;
    width: 33.3%;
    padding: 15px 20px !important;
  }
  .brands--logo ul li:nth-child(1) {
    padding: 0 !important;
  }
  .brands--logo ul li:nth-child(2) {
    padding: 10px 30px !important;
  }
  .work--with--brands {
    font-size: 17px;
    line-height: 30px;
    letter-spacing: -0.03em;
    padding: 0 5px;
  }
  .work--with--brands p {
    padding-right: 0;
  }
}
