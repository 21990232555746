.banner {
  display: flex;
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(53, 71, 44, 0.432292) 56.77%,
    rgba(122, 157, 105, 0) 100%
  );
  padding-top: 150px;
}
.banner .row {
  align-items: center;
}
.img-holder {
  max-width: 372px;
}
.img-holder .desktop-banner {
  display: block;
}
.img-holder .mob-banner {
  display: none;
}
.text-holder {
  padding-right: 16%;
  font-family: "Poppins";
  font-size: 30px;
  line-height: 40px;
  color: #bdbdbd;
}
.head-sec {
  display: block;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #d9d9d9;
}
.head-sec h2.title {
  font-size: 30px;
  font-weight: 700;
  line-height: 40px;
  color: #5ffd04;
  padding-bottom: 30px;
}
.title strong {
  display: flex;
  color: #fff;
}
.title span {
  color: #fff;
  font-weight: 400;
}
.head-sec ul {
  display: flex;
  font-size: 11px;
  line-height: 14px;
  padding-left: 0;
}
.head-sec ul li {
  font-weight: 700;
  color: #828282;
  padding-right: 6px;
  text-transform: uppercase;
}
.btn-sec {
  display: flex;
  padding-top: 40px;
}
.btn-sec button {
  margin-right: 15px;
}
@media screen and (max-width: 1024px) and (min-width: 768px) { 
  .banner {
    padding-top: 40px;
  }
  .head-sec {
    padding-bottom: 22px;
  }
  .text-holder {
    padding-right: 0;
    font-size: 18px;
    line-height: 30px;
  }
  .head-sec h2.title {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .banner {
    padding-top: 80px;
  }
  .head-sec {
    padding-bottom: 11px;
    margin-bottom: 23px;
  }
  .banner .img-holder {
    padding: 0 70px 20px;
    max-width: fit-content;
  }
  .img-holder .desktop-banner {
    display: none;
  }
  .img-holder .mob-banner {
    display: block;
  }
  .text-holder {
    padding-right: 0;
    font-size: 16px;
    line-height: 26px;
  }
  .head-sec .title {
    font-size: 18px;
    line-height: 28px;
  }
  .head-sec h2.title {
    font-size: 19px;
    line-height: 26px;
    padding-bottom: 15px;
  }
  .head-sec ul {
    letter-spacing: 0;
    font-size: 10px;
    line-height: 12px;
    justify-content: space-between;
  }
  .head-sec ul li {
    padding-right: 0;
  }
  .btn-sec {
    display: block;
    padding-top: 20px;
  }
  .btn-sec a {
    display: block;
    margin: 0 0 15px;
    padding: 20px 30px;
  }
  .title strong {
    justify-content: center;
  }
}
