.seprator--green {
  background-color: #5ffd04;
  height: 1px;
  width: 100%;
  margin: 72px 0;
}
@media screen and (max-width: 1024px) and (min-width: 768px) { 
  .seprator--green { 
    margin: 50px 0;
  }
}
@media screen and (max-width: 767px) {
  .seprator--green {
    margin: 40px 0;
  }
}
