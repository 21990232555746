.contact--us {
  float: left;
  width: 100%;
}
.contact--us p strong {
  display: block;
}
.contact--form input[type="text"],
.contact--form input[type="email"],
.contact--form input[type="tel"],
.contact--form textarea {
  background: none;
  width: 100%;
  border: none;
  border-bottom: 1px solid #4f4f4f;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding: 20px 0;
  margin-bottom: 10px;
  color: #4f4f4f;
  margin-bottom: 25px;
  resize: none;
  transition: all;
  transition: all .5s;
}
.contact--form input[type="text"]:focus,
.contact--form input[type="email"]:focus,
.contact--form input[type="tel"]:focus,
.contact--form textarea:focus {
  outline: none;
  border-bottom: 1px solid #fff;
  color: #fff !important;
}
.contact--form input[type="text"]:active,
.contact--form input[type="email"]:active,
.contact--form input[type="number"]:active,
.contact--form textarea:active {
  border: none;
  color: #fff;
}
.contact--form textarea {
  min-height: 100px;
}

.contact--form button {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .contact--us {
    padding: 0 8px;
  }
  .contact--us p{
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .contact--us h2 {
    margin-bottom: 20px;
  }
  .btn.submit {
    margin-bottom: 0;
  }
}