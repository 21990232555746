.social--links {
  padding-left: 10px;
  display: flex;
}
.social--links .linked--in {
  background-image: url("../../assets/Images/linkedin-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  margin-right: 10px;
}
.social--links .linked--in:hover,
.social--links .behance:hover {
  opacity: 0.6;
}
.social--links .behance {
  background-image: url("../../assets/Images/behance-icon.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
}
@media screen and (max-width: 1024px)  and (min-width: 768px ){ 
  .social--links .linked--in,
  .social--links .behance {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 767px) {
  .social--links .linked--in,
  .social--links .behance {
    width: 30px;
    height: 30px;
  }
}
