.main--nav ul {
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
}
.main--nav ul li {
  padding: 0 10px;
}
.main--nav ul li a {
  font-size: 14px;
  font-family: "Poppins";
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  color: #e0e0e0;
  position: relative;
  transition: all 0.5s;
}
.main--nav ul li a:hover {
  transform: scale(0.8);
}
@media screen and (max-width: 1024px) and (min-width: 768px) { 
  .main--nav {
    display: none;
  }
  .responsive-menu {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .main--nav {
    display: none;
  }
}
