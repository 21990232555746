.mentorship--program {
  display: flex;
  background: linear-gradient(
    180deg,
    #000000 0%,
    rgba(53, 71, 44, 0.432292) 56.77%,
    rgba(122, 157, 105, 0) 100%
  );
  display: flex;
}
.mentorship--program .title {
  margin-bottom: 26px;
}
.mentorship--program p {
  margin-bottom: 40px;
}
@media screen and (max-width: 1024x) and (min-width: 768px) { 
  .mentorship--column {
    padding: 8rem 0.8rem 1.8rem;
  }
}
@media screen and (max-width: 767px) {
  .mentorship--program {
    font-size: 16px;
    line-height: 32px;
    padding: 0 5px;
  }
  .mentorship-img-holder {
    margin-bottom: 40px;
  }
  .mentorship--program p {
    margin-bottom: 20px;
  }
  .mentorship--icon {
    max-width: 80px;
    height: 80px;
  }
}