.work--sec {
  display: flex;
}
.work--sec .head--sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 46px;
}
.work--sec .head--sec span {
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: #f2f2f2;
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .work--sec .container-lg {
    padding: 0;
  }
  .work--sec .head--sec {
    padding: 0 16px;
    margin-bottom: 20px;
  }
  .work--sec .right-sec {
    display: none;
  }
}
