.footer {
  float: left;
  width: 100%;
  padding-bottom: 50px;
}
.footer--sec {
  display: flex;
  justify-content: space-between;
  align-content: center;
}
.footer--sec p {
  margin-bottom: 0;
}
.footer--sec .left--sec p {
  float: left;
}
.footer--sec .flag--icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .footer {
    padding-bottom: 28px;
  }
  .footer--sec {
    flex-direction: column;
    margin-top: -13px;
  }
  .footer--sec .left--sec p {
    margin-bottom: 20px;
  }
  .footer--sec .social--links {
    justify-content: center;
    margin: 20px 0;
  }
}