.Header {
  display: flex;
  justify-content: space-between;
  padding: 20px 40px;
  align-items: center;
  position: fixed;
  width: 100%;
  background-color: #000;
  z-index: 99;
}
.nav--section {
  display: flex;
}
/* .sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #000;
  padding: 10px 40px;
  z-index: 999;
} */
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .Header {
    padding: 10px;
  }
}
@media screen and (max-width: 767px) {
  .Header {
    padding: 10px;
  }
}
