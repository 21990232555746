.company--slide {
  width: 100%;
  padding: 0 10px;
}
.company--title {
  font-size: 14px;
  line-height: 21px;
  color: #828282;
}
.slick-dots,.slick-prev, .slick-next {display: none !important;}
@media screen and (max-width:767px) {
  .company--slide {
    padding: 0 30px;
  }
}