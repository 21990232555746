.company--block {
  float: left;
  width: 100%;
  margin-bottom: 80px;
}
.company--block:last-of-type {
  margin-bottom: 0;
}
.company--block .img--holder {
  max-width: 100%;
  margin-bottom: 30px;
}
.company--block .mob-banner {
  display: none;
}
.company--block .text--holder {
  font-size: 20px;
  line-height: 40px;
  padding-right: 5%;
}
.company--block .responsibility {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #828282;
  margin: 10px 0 13px;
}
.company--block p {
  margin-bottom: 24px;
}
.company--block button {
  margin-left: 10px;
}
.company--block .confidential span {
  font-weight: 400;
} 
@media screen and (max-width: 1024px) and (min-width:768px){
  .company--block {margin-bottom: 50px;}
  .company--block .text--holder {padding-right: 0;} 
}
@media screen and (max-width: 767px) {
  .company--block {
    margin-bottom: 30px;
  }
  .company--block .desktop-banner {
    display: none;
  }
  .company--block .mob-banner {
    display: block;
  }
  .company--block .responsibility {
    font-size: 12px;
  }
  .company--block .text--holder {
    padding: 0 20px;
    font-size: 1rem;
    line-height: 30px;
  }
  .company--block .btn--read,
  .company--block .btn--confidential {
    display: block;
    margin: 0 0 10px;
  }
  .company--block button {
    margin-left: 0;
  }
}
@media screen and (max-width: 400px) { 
  .company--block button {
    font-size: 12px;
  }
}

