.mentorship--column {
  min-height: 497px;
  padding: 5rem 1.8rem 1.8rem;
  position: relative;
  background-color: #000;
  border: 1px solid #5ffd04;
  border-radius: 22px;
  box-shadow: 13px 14px 0px -3px rgba(95, 253, 4, 0.41);
  -webkit-box-shadow: 13px 14px 0px -3px rgba(95, 253, 4, 0.41);
  -moz-box-shadow: 13px 14px 0px -3px rgba(95, 253, 4, 0.41);
}
.mentorship-img-holder {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.mentorship--icon {
  max-width: 110px;
  height: 110px;

}
.column---title {
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  font-weight: 700;
}
.mentorship--column strong {
  font-size: 14px;
  line-height: 21px;
  color: #5ffd04;
}
.btn--book--session {
  background-color: #fff;
  border: 2px solid #fff;
  border-radius: 30px;
  display: block;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #000;
  padding: 9px 0;
}
.btn--book--session:hover {
  background-color: transparent;
  color: #fff;
}
@media screen and (max-width: 1024px) and (min-height:768px) { 
  .mentorship--column {
    padding: 8rem 1.1rem 1rem;
  }
  .mentorship--column strong {
    line-height: 30px;
  }
  .mentorship--column p {
    min-height: 133px;
  }
}
@media screen and (max-width: 767px) {
  .mentorship--column {
    margin-bottom: 25px;
    padding: 4rem 3.1rem 1rem;
    text-align: center;
    min-height: auto;
  }
  .mentorship--column p {
    min-height: 100px;
  }
  .btn--book--session {
    margin-bottom: 20px;
  }
}
