@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Fonts/Poppins-Bold.woff") format("woff2"),
    url("../src/assets/Fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../src/assets/Fonts/Poppins-Regular.woff2") format("woff2"),
    url("../src/assets/Fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Metana";
  src: url("../src/assets/Fonts/NeueMetana-Regular.woff2") format("woff2"),
    url("../src/assets/Fonts/NeueMetana-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Metana";
  src: url("../src/assets/Fonts/NeueMetana-Bold.woff2") format("woff2"),
    url("../src/assets/Fonts/NeueMetana-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),
    url(https://example.com/MaterialIcons-Regular.woff) format('woff'),
    url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');
}


body {
  background-color: black;
  font-family: "Poppins";
  color: #bdbdbd;
  font-size: 20px;
  line-height: 40px;
}
img {
  width: 100%;
  height: auto;
  display: block;
}
*:focus {
  outline: none;
}
input:-internal-autofill-selected  {
  color: #fff !important;
  background-color: transparent !important;

}
* {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
  display: inline-block;
}
#root {
  overflow: hidden;
}
.jobs,
.companies--hiring,
.current--opening,
.from--my--experience {
  float: left;
  width: 100%;
}
.companies--hiring h2.title {
  padding-bottom: 40px;
}
.already--applied {
  background-color: #333;
  display: block;
  padding: 15px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
.already--applied a {
  color: #fff;
  font-weight: 700;
}
.marquee {
  margin: 0 -10px;
}
.companies--hiring .slick-slider {
  padding-bottom: 50px;
}
.companies--hiring .slick-slider:after {
  background: linear-gradient(270deg, #000000 29.04%, rgba(0, 0, 0, 0) 89.94%);
  position: absolute;
  right: 0px;
  height: 100%;
  content: "";
  top: 0;
  width: 100px;
  z-index: 1;
}
.current--opening .head-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.current--opening p {
  color: #828282;
}
.opening--job--section {
  position: relative;
}
.opening--job--section:after {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  border-radius: 22px;
  min-height: 314px;
}
.get--all--access {
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  z-index: 9;
  text-align: center;
  display: block;
  position: relative;
  margin-top: 10px;
}
.get--all--access:hover {
  color: #fff;
  text-decoration: underline;
}
h1.title {
  font-size: 60px;
  line-height: 90px;
  color: #fff;
  margin-bottom: 0;
  padding-bottom: 12px;
  font-weight: 700;
}
h2.title {
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  margin-bottom: 0;
  font-weight: 700;
  font-style: normal;
}
.btn {
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
}
.btn:hover {
  background-color: #fff;
  color: black;
  transform: scale(0.9);
}
@media screen and (max-width: 1024px) and (min-width:768px) {
  p {
    font-size: 16px;
    line-height: 32px;
    margin-bottom: 0;
  }
  h2.title {
    font-size: 24px;
    margin-top: -10px;
  }
  .btn {
    font-size: 12px;
  }
  h1.title {
    font-size: 30px;
    line-height: 50px;
  }
  .footer--sec p {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  body {
    text-align: center;
  }
  .title {
    font-size: 18px;
    line-height: 28px;
  }
  h2.title {
    font-size: 26px;
    line-height: 34px;
  }
  .btn {
    width: 100%;
    margin: 0;
    font-size: 12px; 
    margin-bottom: 10px; 
  }
  .btn-sec button {
      margin-bottom: 10px !important;
  }
  .btn-sec button:nth-child(2) {
    margin-bottom: 0 !important;
  }
  .company--block button {
    margin-left: 0;
  }
  .btn {
    font-size: 1rem;
  }
  .btn:hover {
    transform: scale(1);
  }
}
